import product1 from "../../../assets/images/product1.png";
import product2 from "../../../assets/images/product2.png";
import product3 from "../../../assets/images/product3.jpg";
import defaultImg from "../../../assets/images/default.png";
import { ProductService } from "../../../services/ProductService";
import { alertActions } from "../../../actions/alert.actions";
import "./ProductList.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import sample from "../../../assets/images/default.png";
import { OrderService } from "../../../services/OrderService";
import { ContactService } from "../../../services/ContactService";
const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url
const useProductList = (location) => {
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState();
  const [prodList, setProdList] = useState();
  const data1 = window.location.href.split("/")[4];
  const data3 = window.location.href.split("/")[5];
  const [current, setcurrent] = useState(localStorage.getItem("pagination"));
  const [pageCount, setPageCount] = useState(0);
  const [prodValue, setProdValue] = useState();
  let [min_amount, setMin_amount] = useState();
  let [max_amount, setMax_amount] = useState();
  const [count, setCount] = useState();
  let [subCat, setSubCat] = useState(null);
  let pathname = window.location.pathname;
  const [subChanged, setSubChanged] = useState(false);

  //search filters
  const [filter, setFilter] = useState(
    location?.state?.value2 !== undefined &&
      (location?.state?.value2 === "men" ||
        location?.state?.value2 === "women" ||
        location?.state?.value2 === "kids")
      ? [location?.state?.value2]
      : []
  );
  const [main, setMain] = useState(
    location?.state?.name !== undefined ? [location?.state?.name] : []
  );
  const [sub, setSub] = useState(
    location?.state?.value2 !== undefined &&
      (location?.state?.value2 !== "men" ||
        location?.state?.value2 !== "women" ||
        location?.state?.value2 !== "kids")
      ? [location?.state?.value2]
      : []
  );

  const usr_id = useSelector((state) => state?.userData?.user?._id);
  let history = useNavigate();
  const [sub_cat, setSub_cat] = useState([]);

  var newsearch1;
  var newsearch2;
  var newsearch3 = data1.replace(/[' '\r]/g, "");

  if (/^[A-Za-z0-9]*$/.test(data1)) {
    newsearch1 = data1;
  } else {
    newsearch2 = data1.replace(/[-\r]/g, " ");
    newsearch1 = newsearch2.replace(/[%20\r]/g, "");
    if (newsearch1.includes("Diamond Polki")) {
      newsearch1 = "Polki";
    }
  }
  var filterName;
  filterName =
    newsearch1.includes("women") ||
    newsearch1.includes("men") ||
    newsearch1.includes("kids");
  var newsearchs = newsearch1.replace(/  /g, "");
  var newsearch = newsearchs.replace(/jewellery/g, "");
  // var newsearch = newsearchs.replace(/[-\r]/g, "");
  const [search, setSearch] = useState(newsearch);

  const changeEvent = location;
  const [val, setVal] = useState("");
  const [mobileFilter, showMobileFilter] = useState(false);
  let nm = location?.state?.name;
  const [loading, setLoading] = useState("loading");

  //new changes
  let strArray = data1.split("-"); // Split the string into an array based on the hyphen separator
  let ProductName = strArray[0];

  //title
  const urlParams = new URLSearchParams(window.location.search);

  // Set the page title
  const words = data1.split("-"); // split the string by hyphen
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  ); // capitalize the first letter of each word
  const productName = capitalizedWords.join(" "); // join the words back into a single string with a space separator
  document.title = `Buy ${productName} | Latest ${productName} Designs | Abharan Jewellers`;

  let metaData = {
    info: `Shop Latest ${productName}  At Best Price. Best ${productName} Designs Online In India. 100% Certified Hallmark`,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);

  // setSKUName({ SKUProductName })

  useEffect(() => {
    // setSearch(location?.state?.searchData);
    if (location?.state?.name !== undefined) {
      setMain([location?.state?.name]);
    } else {
      setMain([]);
    }

    if (
      pathname === "/product-list/diamond-necklaces" ||
      pathname === "/product-list/gold-necklaces"
    ) {
      setMax_amount(200000);
      max_amount = 200000;
    } else {
      setMax_amount([]);
    }

    setcurrent(localStorage.getItem("pagination"));

    if (location?.state?.value2 !== undefined) {
      if (isGenderSelected(location?.state?.value2)) {
        setSub([]);
        //setSearch("");
        setFilter([location?.state?.value2]);
        // localStorage.setItem("gen", location?.state?.value2)
      } else {
        setFilter([]);
        setSub([location?.state?.value2]);
      }
    } else {
      setFilter([]);
      setSub([]);
    }

    if (localStorage.getItem("sub") !== "null") {
      var updatedList;
      let elements = [];
      updatedList = localStorage.getItem("sub");
      if (updatedList) {
        let lines = updatedList.split(",");

        for (var i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }
        // setSub([updatedList.splice(",")]);
        if (elements[0] !== "") {
          setSub(elements);
        } else {
          setSub([]);
        }
      }
    }
    if (main.length >= 1 && localStorage.getItem("main") !== "null") {
      var updatedList1;
      let elements1 = [];
      updatedList1 = localStorage.getItem("main");
      if (updatedList1) {
        let lines1 = updatedList1.split(",");
        for (var i = 0; i < lines1.length; i++) {
          elements1.push(lines1[i]);
        }
        // setSub([updatedList.splice(",")]);
        if (elements1[0] !== "") {
          setMain(elements1);
        } else {
          setMain([]);
        }
        // setMain(elements1);
      }
    }

    if (localStorage.getItem("gender") !== "null") {
      var updatedList2;
      let elements2 = [];
      if (updatedList) {
        updatedList2 = localStorage.getItem("main");
        let lines2 = updatedList2?.split(",");
        for (var i = 0; i < lines2?.length; i++) {
          elements2.push(lines2?.[i]);
          // if (i < lines.length-1) {
          //     elements.push(<br key={i}/>);
          // }
        }
        if (elements2[0] !== "") {
          setFilter(elements2);
        } else {
          setFilter([]);
        }
      }

    }

    if (localStorage.getItem("min_amount") !== "null") {
      setMin_amount(localStorage.getItem("min_amount"));
      // min_amount = localStorage.getItem("min_amount");
    }
    if (localStorage.getItem("max_amount") !== "null") {
      setMax_amount(localStorage.getItem("max_amount"));
      //max_amount = localStorage.getItem("max_amount");
    }
    if (localStorage.getItem("search") !== "null") {
      setSearch(localStorage.getItem("search"));
    } else {
      if (filterName) {
        //setSearch("");
      } else {
        setSearch(newsearch);
      }
    }
  }, [location]);

  // alert(location.state.searchData);

  useEffect(() => {
    if (sub_cat.length === 0) return;
    let searchData = "";
    if (localStorage.getItem("search") === "") {
      searchData = search;
    } else {
      if (search && search && search.length > 1) {
        searchData = search;
      } else if (location?.state?.searchData) {
        searchData = location?.state?.searchData;
      } else {
        if (filterName || main.length >= 1) {
          searchData = "";
        } else {
          searchData = newsearch;
        }
      }
    }
    setLoading("loading");
    ProductService.getData(
      searchData,
      current,
      filter ? filter : "",
      min_amount ? min_amount : 100,
      max_amount ? max_amount : "",
      main ? main : "",
      sub ? sub : ""
    )
      .then((productList) => {
        setLoading("notempty");
        setProductList(productList?.data?.data?.products?.data);
        setPageCount(productList?.data?.data?.products?.last_page);
        if (productList?.data?.data?.products?.last_page != 1) {
          if (
            productList?.data?.data?.products?.last_page <=
            productList?.data?.data?.products?.current_page
          ) {
            setcurrent(productList?.data?.data?.products?.last_page);
            localStorage.setItem(
              "pagination",
              productList?.data?.data?.products?.last_page
            );
          }
        }
      })
      .catch((error) => {
        if (
          error === "Cannot read properties of undefined (reading 'status')"
        ) {
          setLoading("loading");
        } else {
          //setLoading("empty");
          setLoading("loading");
        }
      });
  }, [
    sub,
    main,
    search,
    min_amount,
    max_amount,
    count,
    current,
    filter,
    sub_cat,
  ]);

  useEffect(() => {
    ContactService.getBranch().then((sub_cat) => {
      setSub_cat(sub_cat.data.data.sub_category);
    });
  }, []);

  const isGenderSelected = (val) => {
    return val.includes("men") || val.includes("women") || val.includes("kids");
  };

  function handleChange(event) {
    localStorage.setItem("search", "");
    localStorage.setItem("pagination", 1);
    setcurrent(localStorage.getItem("pagination"));
    // console.log("Pagination Value", localStorage.getItem("pagination"));
    const { name, value } = event.target;

    if (event.target.name === "gender") {
      var updatedList = [...filter];
      if (event.target.checked) {
        updatedList = [...filter, event.target.value];
      } else {
        updatedList.splice(filter.indexOf(event.target.value), 1);
      }
      localStorage.setItem("gender", [updatedList]);
      setFilter(updatedList);
      filter = updatedList;
      setSearch("");
    }

    // min_amount
    if (event.target.name === "min_amount") {
      setMin_amount(event.target.value);
      min_amount = event.target.value;
      //setSearch("");
      localStorage.setItem("min_amount", event.target.value);
    }

    // max_amount
    if (event.target.name === "max_amount") {
      setMax_amount(event.target.value);
      max_amount = event.target.value;
      //setSearch("");
      localStorage.setItem("max_amount", event.target.value);
    }
    //main category
    if (event.target.name === "main") {
      // alert("hai")
      var updatedList = [...main];
      if (event.target.checked) {
        updatedList = [...main, event.target.value];
      } else {
        updatedList.splice(main.indexOf(event.target.value), 1);
      }
      setMain(updatedList);
      //setSearch("");
      localStorage.setItem("main", [updatedList]);
    }
    //sub category
    if (event.target.name === "sub") {
      var updatedList = [...sub];

      if (event.target.checked) {
        updatedList = [...sub, event.target.value];
      } else {
        updatedList.splice(sub.indexOf(event.target.value), 1);
      }

      setSub(updatedList);
      //setSearch("");
      localStorage.setItem("sub", [updatedList]);
      // console.log(sub)
    }
  }

  //check function

  const isInMainCategory = (title) => {
    return main.includes(title);
  };

  const isInSubCategory = (title) => {
    // setSubCat(sub.includes(title));
    subCat = sub.includes(title);
    return sub.includes(title);
  };

  const isInGenderCategory = (title) => {
    return filter.includes(title);
  };

  const imgChange = (prod) => {
    if (usr_id) {
      ProductService.getProdDetails(prod.pro_details.product_id, usr_id)
        .then((prodList) => {
          // alert("HI");
          setProdList(prodList);
          wishListCount();

          if (prodList.status.code === 4) {
            dispatch(alertActions.error(prodList.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);

          dispatch(alertActions.error("Please login....!"));

          history("/login");
          //   setLoading(false);
        });
    } else {
      history("/login");
    }
  };

  const homeREdirect = () => {
    history("/");
  };
  const collectionREdirect = () => {
    history("/product-list/gold");
  };

  const wishListCount = () => {
    if (user_id) {
      OrderService.getWishlist(user_id)
        .then((order) => {
          setCount(order?.data?.data?.total);
          return true;
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
          return false;
        });
    }
  };

  function changeHandler(e) {
    setSearch(e.target.value);

    localStorage.setItem("pagination", 1);
    setcurrent(localStorage.getItem("pagination"));
  }

  const changeCurrentPage = (page) => {
    setcurrent(page.selected + 1);
    localStorage.setItem("pagination", page.selected + 1);
  };

  // new code
  const displayProduct = (display) => {
    if (location.pathname !== "/login") {
      localStorage.setItem("pathname", window.location.pathname);
    }
    // alert(JSON.stringify(display.pro_details.product_id));
    ProductService.productClickCount(display?.pro_details?.product_id);
    const sku = display.SKU;
    const productName = display?.main_cat?.title
      .toLowerCase()
      .replace(/\s+/g, "-");
    const prodNameData = display?.name.replace(/\s+/g, "-");
    const url = `/product-details/${productName}/${prodNameData.toLowerCase()}/${sku.toLowerCase()}`;
    navigate(url, {
      state: { id: sku, userid: usr_id, name: location?.state?.name },
    });
    setProdValue(display);
  };

  const openFilter = () => {
    showMobileFilter(true);
    document.body.classList.add("modal-shadow");
  };

  const closeFilter = () => {
    showMobileFilter(false);
    document.body.classList.remove("modal-shadow");
  };

  useEffect(() => {
    const subFromStorage = localStorage.getItem("sub");
    if (
      subFromStorage &&
      subFromStorage !== "null" &&
      Array.isArray(subFromStorage)
    ) {
      setSub(subFromStorage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("sub", sub);
    if (sub.length === 1 && location.state.value2 !== sub[0]) {
      setSubChanged(true);
    }
  }, [sub]);

  const handleClearSingleSubCat = (index) => {
    if (sub.length !== 0) {
      const newSub = [...sub];
      newSub.splice(index, 1);
      setSub(newSub);
    } else {
      setSub([]);
    }
  };
  const handleClearSubCat = () => {
    setSub([]);
  };

  // new code

  return {
    product1,
    product2,
    product3,
    productList,
    image_url,
    changeCurrentPage,
    pageCount,
    current,
    changeHandler,
    search,
    imgChange,
    val,
    displayProduct,
    defaultImg,
    sample,
    handleChange,
    count,
    homeREdirect,
    collectionREdirect,
    sub_cat,
    isInMainCategory,
    isInSubCategory,
    isInGenderCategory,
    mobileFilter,
    openFilter,
    closeFilter,
    loading,
    max_amount,
    min_amount,
    data1,
    ProductName,
    metaData,
    sub,
    handleClearSubCat,
    handleClearSingleSubCat,
  };
};
export default useProductList;
