import useProductList from "./useProductList";
import ReactPaginate from "react-paginate";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import cart_empty from "../../../assets/images/empty-cart.png";
import { commaValidation } from "../../../utility";
import loader from "../../../assets/images/loader.png";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductList = () => {
  const location = useLocation();
  let pg = localStorage.getItem("pagination");
  // var SKUProductName = props;
  const {
    productList,
    image_url,
    pageCount,
    current,
    changeCurrentPage,
    changeHandler,
    search,
    imgChange,
    displayProduct,
    sample,
    handleChange,
    count,
    collectionREdirect,
    sub_cat,
    isInMainCategory,
    isInSubCategory,
    isInGenderCategory,
    mobileFilter,
    openFilter,
    closeFilter,
    handleClearSubCat,
    handleClearSingleSubCat,
    loading,
    max_amount,
    min_amount,
    data1,
    ProductName,
    metaData,
    sub,
  } = useProductList(location);

  let showitem = window.location.href.split("/")[4];

  const ProductFirst = () => {
    const [accordion, setAccordion] = useState(false);
    const [visible, setVisible] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const menuData = useSelector((state) => state.menuData.menu);
    // const subCat = useSelector((state) => state.subCatData);
    const menudt = useSelector((state) => state.menuData);

    return (
      <div className="col-sm-2">
        <Helmet>
          <meta name="description" content={metaData?.info} />
          <link rel="canonical" href={window.location.href} />
          {/* <meta name="robots" content="noindex" /> */}
        </Helmet>

        <div className="product_left flt">
          <h1 className="breadcrumbs flex_center flt">
            <Link to="/">HOME</Link> /
            <span onClick={collectionREdirect}>COLLECTION</span>
          </h1>
          <div className="filler hide_desktop"></div>
          <button
            onClick={openFilter}
            className="product_filter_open hide_desktop"
          >
            Filter
          </button>

          <div
            className={
              "product_filter flt" + (mobileFilter ? " filter_show" : "")
            }
          >
            <div className="product_filter_cont flt">
              <div className="filter_box flt">
                <div className="filter_head flt">
                  <a
                    onClick={() => setShow((prev) => !prev)}
                    className={show ? "collapsed" : ""}
                  >
                    GENDER{" "}
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </a>
                </div>
                <div className={"filter_cont flt" + (show ? " show" : "")}>
                  <form>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="men"
                        name="gender"
                        checked={isInGenderCategory("men")}
                        value="men"
                        onChange={handleChange}
                      />
                      <label for="men">Men</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="women"
                        name="gender"
                        value="women"
                        checked={isInGenderCategory("women")}
                        onChange={handleChange}
                      />
                      <label for="women">Women</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="kids"
                        name="gender"
                        value="kids"
                        checked={isInGenderCategory("kids")}
                        onChange={handleChange}
                      />
                      <label for="kids">Kids</label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="filter_box flt">
                <div className="filter_head flt">
                  <a
                    onClick={() => setShow1((prev) => !prev)}
                    className={show1 ? "collapsed" : ""}
                  >
                    PRICES{" "}
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </a>
                </div>
                <div className={"filter_cont flt" + (show1 ? " show" : "")}>
                  <div className="filter_input flt">
                    <span>
                      <input
                        type="number"
                        placeholder="Min"
                        name="min_amount"
                        value={min_amount}
                        onChange={handleChange}
                      />
                    </span>
                    <span>
                      <input
                        type="number"
                        placeholder="Max"
                        name="max_amount"
                        value={max_amount}
                        onChange={handleChange}
                      />
                    </span>
                    <button>Go</button>
                  </div>
                </div>
              </div>
              <div className="filter_box flt">
                <div className="filter_head flt">
                  <a
                    onClick={() => setShow2((prev) => !prev)}
                    className={show2 ? "collapsed" : ""}
                  >
                    MAIN CATEGORIES{" "}
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </a>
                </div>
                <div className={"filter_cont flt" + (show2 ? " show" : "")}>
                  <form>
                    {menuData && menuData.main_category
                      ? menuData.main_category.map((main_cat) => (
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={main_cat.title.toLowerCase()}
                              value={main_cat.title}
                              onChange={handleChange}
                              name="main"
                              checked={isInMainCategory(main_cat.title)}
                            />
                            <label for={main_cat.title.toLowerCase()}>
                              {main_cat.title}
                            </label>
                          </div>
                        ))
                      : null}
                  </form>
                </div>
              </div>
              <div className="filter_box flt">
                <div className="filter_head flt">
                  <a
                    onClick={() => setShow3((prev) => !prev)}
                    className={show3 ? "collapsed" : ""}
                  >
                    PRODUCT CATEGORIES{" "}
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </a>
                </div>
                <div className={"filter_cont flt" + (show3 ? " show" : "")}>
                  <form>
                    {sub_cat
                      ? sub_cat.map((main_cat) => (
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={main_cat.title}
                              onChange={handleChange}
                              value={main_cat.title}
                              checked={isInSubCategory(main_cat.title)}
                              name="sub"
                            />
                            <label for={main_cat.title}>{main_cat.title}</label>
                          </div>
                        ))
                      : null}
                  </form>
                </div>
              </div>
            </div>
            <button
              onClick={closeFilter}
              className="product_filter_close hide_desktop flt"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  };

  const productSecond = () => {
    return (
      <div className="col-sm-10">
        <div className="product_right flt">
          <div className="product_bar flt">
            <span>
              <input
                type="text"
                placeholder="Search for items"
                name="search"
                value={search && search}
                onChange={changeHandler}
              />
            </span>
          </div>

          {/* old code */}
          <div className="product_tag_main flt">
            <ul className="product_tag_list flt">
              {sub?.map((ele, id) => {
                return (
                  <li key={id}>
                    {ele}
                    <i
                      className="fas fa-times"
                      onClick={() => handleClearSingleSubCat(id)}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="product_tab_btn flt">
              {sub.length > 0 && (
                <button onClick={handleClearSubCat}>Clear All</button>
              )}
            </div>
          </div>
          <div className="product_list flt">
            {loading === "loading" && emptypg()}
            <div className="row">
              {productList && productList.length > 0 ? (
                productList.map((prod) => (
                  <div className="col-sm-4">
                    <div className="product_box flt">
                      <div className="product_image flt">
                        <a onClick={() => displayProduct(prod ? prod : "")}>
                          {prod?.pro_images?.[0]?.image &&
                          prod?.pro_images?.[0]?.image !== "" ? (
                            <LazyLoadImage
                              src={image_url + prod.pro_images[0].image}
                              alt="image"
                            />
                          ) : (
                            <LazyLoadImage src={sample} class="example" />
                          )}
                        </a>

                        <div
                          className={
                            prod && prod.wish_status && prod.wish_status === 1
                              ? "heart heart_red"
                              : "heart "
                          }
                          onClick={() => imgChange(prod ? prod : "")}
                        >
                          <i className="fa fa-heart" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div className="product_txt flt">
                        <div className="product_desc flt">
                          <div className="product_name">{prod.name}</div>
                          <div className="product_rate">
                            ₹ <span>{commaValidation(prod.c_gross_value)}</span>
                          </div>
                        </div>

                        {prod?.pro_details?.weight &&
                        prod?.pro_details?.weight !== 0 &&
                        prod?.pro_details?.weight !== "0" ? (
                          <div className="product_weight flt">
                            {prod?.pro_details?.weight} GM
                          </div>
                        ) : (
                          <div className="product_weight flt">&nbsp;</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-sm-12">
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={cart_empty} alt="image" />
                    </div>
                    <div class="empty_order_head flt">No products found</div>
                  </div>
                </div>
              )}

              {/* {alert(JSON.Stringyfy(productList))} */}
            </div>
          </div>
        </div>
        <div className="paginationdiv flt">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            // currentPage={current}
            showFirstLastPages={true}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={changeCurrentPage}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={current - 1}
          />
        </div>
      </div>
    );
  };
  const emptypg = () => {
    return (
      <div className="cartpg flt">
        <img src={loader} alt="abharan" />
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={count} />;
  };
  const renderFooter = () => {
    return <Footer data={ProductName} data1={data1} />;
  };
  return (
    <div>
      {renderHeader()}
      <div class="product_container flt">
        <div class="row">
          {/* {loading === "loading" && emptypg()} */}
          {ProductFirst()}
          {productSecond()}
        </div>
      </div>

      {renderFooter()}
    </div>
  );
};
export default ProductList;
